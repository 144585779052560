.burger__menu-button {
  border: none;
}

.burger__menu-button_open {
  background: no-repeat 50% url("../../images/burger-menu-icon.svg") #252525;
  padding: 0;
  width: 44px;
  height: 44px;
  transition: 0.3s ease-in-out;
}

.burger__menu-button_close {
  background: no-repeat 50% url("../../images/burger-menu-cross-icon.svg")
    #252525;
  position: fixed;
  top: 22px;
  right: 22px;
  z-index: 5;
  padding: 0;
  width: 22px;
  height: 22px;
  transition: 0.3s ease-in-out;
}

.burger__menu {
  font-size: 18px;
  line-height: normal;
  font-weight: normal;
  visibility: hidden;
  margin: 0;
  max-width: 520px;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  background-color: #252525;
  height: 100vh;
  justify-content: center;
  list-style: none;
  align-items: center;
  gap: 15px;
}

.burger__menu:before {
  content: "";
  width: 100%;
  height: 100vh;
  display: block;
  background: #252525;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: -100%;
}

@keyframes slideMenu {
  0% {
    transform: translate(100%);
  }
}

.burger__menu_opened {
  visibility: visible;
  opacity: 1;
  display: flex;
  animation: slideMenu 250ms ease;
}
