.footer {
  grid-area: footer;
  color: var(--color-dark);
  width: 100%;
  margin: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light);
}

.footer__copyright {
  padding: 0 70px;
  text-align: right;
}
