.main {
  display: grid;
  grid-area: main;
  grid-template-areas:
    "promo"
    "members"
    "about";
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin: 0;
  background-color: var(--color-dark);
}