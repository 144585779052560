.members-cards__list {
  width: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-items: center;

}

.members-cards__list-item {
  width: 100%;
  display: flex;
  max-height: 500px;
  height: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  transition: 1s;
}

.members-cards__list-link {
  color: var(--color-light);
  filter: grayscale(100%);
}

.members-cards__list-link::before {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 1s ease-in-out;
}

.members-cards__title {
  position: absolute;
  z-index: 1;
  font-size: 45px;
  font-weight: normal;
  margin: 0;
}

.members-cards__subtitle {
  position: absolute;
  z-index: 1;
  font-size: 30px;
  font-weight: normal;
  margin: 0;
}

.members-cards__image {
  object-fit: cover;
  width: 100%;
  min-height: 400px;
  height: 100%;
}

.members-cards__list-link:hover {
  transition: 1s;
  filter: none;
}