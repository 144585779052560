.header__menu {
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.header__menu-item {
  list-style-type: none;
}

.header__menu-link {
  text-decoration: none;
  color: #fff;
}

.header__menu-link_active {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .header__menu {
    gap: 20px;
  }
}
