.members {
  display: flex;
  grid-area: members;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  background-color: var(--color-dark);
}

.members__title {
  z-index: 1;
  margin: 0;
  font-weight: bold;
  font-size: 200px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
}