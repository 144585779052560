@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Bold.woff2") format("woff2"),
    url("./fonts/Oswald-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Light.woff2") format("woff2"),
    url("./fonts/Oswald-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-ExtraLight.woff2") format("woff2"),
    url("./fonts/Oswald-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-SemiBold.woff2") format("woff2"),
    url("./fonts/Oswald-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Regular.woff2") format("woff2"),
    url("./fonts/Oswald-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Medium.woff2") format("woff2"),
    url("./fonts/Oswald-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
