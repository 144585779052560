.about {
  display: flex;
  justify-content: center;
  background-color: var(--color-light);
  color: var(--color-dark);
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.about__info {
  padding: 50px 70px;
  width: 100%;
  max-width: 1141px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0;
}

.about__title {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 60px;
  text-decoration: underline;
  font-weight: 600;
  margin: 0 0 50px 0;
}

.about__text {
  font-size: 25px;
  font-weight: 300;
  color: var(--color-dark);
  max-width: 600px;
  margin: 0 0 15px 0;
  line-height: 1.5;
}

.about__text:last-child {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .about__info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 580px) {
  .about__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .about__title {
    font-size: 50px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 580px) {
  .about__title {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .about__text {
    font-size: 21px;
  }
}

@media screen and (max-width: 580px) {
  .about__text {
    font-size: 18px;
  }
}
