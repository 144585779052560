.promo {
  grid-area: promo;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark);
  /* max-height: 600px; */
  height: calc(100vh - 60px);
  width: 100%;
}

.promo__title {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  font-size: 200px;
  text-decoration: underline;
  font-weight: bold;
  color: #fff;
  position: absolute;
  z-index: 1;
  margin: 0;
}

.promo__logo {
  max-height: 500px;
  object-fit: contain;
  position: relative;
  width: 100%;
  opacity: 0.03;
}

@media screen and (max-width: 768px) {
  .promo__title {
    font-size: 150px;
  }
}

@media screen and (max-width: 580px) {
  .promo__title {
    font-size: 100px;
  }
}

@media screen and (max-width: 450px) {
  .promo__title {
    font-size: 75px;
  }
}