.app {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  justify-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  --color-dark: #252525;
  --color-light: #ffffff;
  color: var(--color-light);
  align-items: center;
  font: normal 14px/20px "Oswald", Arial, sans-serif;
  line-height: 1;
  -webkit-text-size-adjust: auto;
  -ms-text-size-adjust: auto;
  -moz-text-size-adjust: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hover-effect {
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.hover-effect:hover {
  opacity: 0.5;
}

.section {
  width: 100%;
  max-width: 1140px;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .section {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 580px) {
  .section {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 450px) {
  .section {
    padding-left: 15px;
    padding-right: 15px;
  }
}
