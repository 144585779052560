.header {
  grid-area: header;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: var(--color-dark);
}

.header__navigation {
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.header__logo {
  max-width: 32px;
  max-height: 32px;
}

.header__link {
}
